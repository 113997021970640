<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <div class="container">
        <form @submit.prevent="handleCriar">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h1 class="h1-seo description">Recuperar senha</h1>
              </div>

              <p class="lead description">
                Informe a nova senha para poder ter acesso ao Ambiente.
              </p>

              <fg-input
                class="no-border input-lg"
                addon-left-icon="now-ui-icons  ui-1_lock-circle-open"
                placeholder="Senha"
                inputType="password"
                v-model="payloadReset.password"
              >
              </fg-input>

              <fg-input
                class="no-border input-lg"
                addon-left-icon="now-ui-icons  ui-1_lock-circle-open"
                placeholder="Repetir senha"
                inputType="password"
                v-model="payloadReset.confirmPassword"
              >
              </fg-input>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <b-btn class="btn-primary btn-round btn-lg btn-block" type="submit">Alterar</b-btn>
                </div>
              </template>
            </card>
          </div>
        </form>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
import { userService } from '@/_services';

import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      backgroundLogin: "background-image: url('/img/trevo.jpg')",
      submitted: false,

      code: undefined,

      payloadReset: {}
    };
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
      const siglaAssets = window.config.SIGLA_ASSETS;
      this.backgroundLogin = `background-image: url('/img/${siglaAssets}/trevo.jpg')`;
    }

    this.code = this.$route.params.id;
  },
  methods: {
    handleCriar() {
      // Método para processamento criação senha

      if (this.payloadReset.password === undefined) {
        this.$notify({
          group: 'msgs',
          title: 'Erro',
          text: 'Senha não informada.',
          type: 'error'
        });
        return;
      }

      if (this.payloadReset.confirmPassword === undefined) {
        this.$notify({
          group: 'msgs',
          title: 'Erro',
          text: 'Confirmar senha não informada.',
          type: 'error'
        });
        return;
      }

      userService
        .registerConfirm(this.payloadReset, this.code)
        .then(() => {
          this.msgTxt = 'Senha alterada com sucesso.';
          this.$notify({
            group: 'msgs',
            title: 'Registro',
            text: 'Senha alterada com sucesso.',
            type: 'info'
          });

          this.$router.push('/');
        })
        .catch(error => {
          console.log('error=>', error);
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });
    }
  }
};
</script>
<style></style>
