import Vue from 'vue';
import Router from 'vue-router';

import Index from '../pages/Index.vue';
import FaleConosco from '../pages/FaleConosco.vue';
import NovaDemanda from '../pages/NovaDemanda.vue';
import Demanda from '../pages/Demanda.vue';
import Faqs from '../pages/Faqs.vue';
import Login from '../pages/Login.vue';
import MainNavbar from '../layout/MainNavbar.vue';
import MainFooter from '../layout/MainFooter.vue';
import Mapas from '../pages/Mapas.vue';
import Perfil from '../pages/Perfil.vue';
import PesquisaDemanda from '../pages/PesquisaDemanda.vue';
import Recuperar from '../pages/RecuperarSenha.vue';
import RecuperarConfirm from '../pages/RecuperarSenhaConfirm.vue';
import RecuperarInfo from '../pages/RecuperarSenhaInfo.vue';
import RecuperarForm from '../pages/RecuperarSenhaForm.vue';
import Registro from '../pages/Registro.vue';
import RegistroConfirm from '../pages/RegistroConfirm.vue';

Vue.use(Router);

export const router = new Router({
  base: process.env.VUE_APP_CONTEXT,
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar },
      props: {
        header: { mostrarLogo: true, colorOnScroll: 400 }
        // footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/register',
      name: 'registro',
      components: { default: Registro },
      props: {
        header: { colorOnScroll: 0 }
      }
    },
    {
      path: '/confirm/:id',
      name: 'registroConfirm',
      components: { default: RegistroConfirm, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/recuperar',
      name: 'recuperar',
      components: { default: Recuperar, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/recuperarForm',
      name: 'recuperarForm',
      components: { default: RecuperarForm, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/resetInfo',
      name: 'resetInfo',
      components: { default: RecuperarInfo, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/resetConfirm/:id',
      name: 'resetConfirm',
      components: { default: RecuperarConfirm, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 }
      }
    },
    {
      path: '/perfil',
      name: 'perfil',
      components: { default: Perfil, header: MainNavbar },
      props: {
        header: { colorOnScroll: 100 }
      }
    },
    {
      path: '/mapas',
      name: 'mapas',
      components: { default: Mapas, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 30 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/novaDemanda',
      name: 'novaDemanda',
      components: { default: NovaDemanda, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/pesquisaDemanda',
      name: 'pesquisaDemanda',
      components: { default: PesquisaDemanda, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 60 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/demanda/:idDemanda',
      name: 'demanda',
      components: { default: Demanda, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/faleConosco',
      name: 'faleConosco',
      components: { default: FaleConosco, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/faqs',
      name: 'faqs',
      components: { default: Faqs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50 },
        footer: { backgroundColor: 'black' }
      }
    },
    // otherwise redirect to home
    // { path: '*', redirect: '/' }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/confirm', '/recuperar', '/recuperarForm', '/resetInfo'];
  let authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  // Estas rotas includem parametros de roteamento
  if (to.path.startsWith('/confirm') || to.path.startsWith('/resetConfirm')) authRequired = false;

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  Vue.nextTick(() => {
    if (
      authRequired &&
      (router.app.$store.state.empresa.empresaAtual === undefined ||
        router.app.$store.state.empresa.empresaAtual === null)
    )
      return next('/login');
  });

  next();
});
