<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">Fale conosco</h2>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="onSubmit" class="col-md-8 ml-auto mr-auto text-center">
          <p class="description">Preencha os dados abaixo e escolha Enviar.</p>
          <b-card>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Motivo:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-select
                    :state="validateState('objetivo')"
                    class="form-control"
                    id="objetivo"
                    name="objetivo"
                    v-model="faleConosco.motivoContato"
                    v-validate="'required'"
                    aria-describedby="objetivo-feedback"
                  >
                    <template #first>
                      <b-form-select-option :value="undefined">
                        Selecione...
                      </b-form-select-option>
                    </template>
                    <b-form-select-option :value="1">Informações</b-form-select-option>
                    <b-form-select-option :value="2">Dúvidas</b-form-select-option>
                    <b-form-select-option :value="3">Outros</b-form-select-option>
                  </b-form-select>

                  <b-form-invalid-feedback id="objetivo-feedback">Motivo do Contato</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Assunto:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    :state="validateState('assunto')"
                    id="assunto"
                    name="assunto"
                    placeholder="Informe o assunto"
                    v-model="faleConosco.assunto"
                    v-validate="'required'"
                    aria-describedby="assunto-feedback"
                    maxlength="200"
                    autofocus
                  ></b-form-input>

                  <b-form-invalid-feedback id="assuno-feedback"
                    >É necessário informar o campo 'Assunto'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Nome/Razão Social:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    :state="validateState('nome')"
                    id="nome"
                    name="nome"
                    placeholder="Nome/Razão Social"
                    v-model="faleConosco.nome"
                    v-validate="'required'"
                    aria-describedby="nome-feedback"
                    maxlength="100"
                    autofocus
                  ></b-form-input>

                  <b-form-invalid-feedback id="nome-feedback"
                    >É necessário informar o campo 'Nome/Razão social'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="E-mail:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    :state="validateState('email')"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    v-model="faleConosco.email"
                    v-validate="'required'"
                    aria-describedby="email-feedback"
                    maxlength="150"
                    autofocus
                  ></b-form-input>

                  <b-form-invalid-feedback id="email-feedback"
                    >É necessário informar o campo 'E-mail'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Endereço:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    id="endereco"
                    name="endereco"
                    placeholder="Dados de Endereço"
                    v-model="faleConosco.endereco"
                    maxlength="150"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Telefone"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    id="telefone"
                    name="telefone"
                    placeholder="Telefone/Celular"
                    v-model="faleConosco.telefone"
                    maxlength="50"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label="UF:" label-cols-lg="3" label-size="sm" label-text-align="left" class="text-left">
                  <b-form-select class="form-control" v-model="uf" @change="handleApiCidades">
                    <template #first>
                      <b-form-select-option :value="null">
                        Selecione...
                      </b-form-select-option>
                    </template>
                    <b-form-select-option value="AC">AC</b-form-select-option>
                    <b-form-select-option value="AL">AL</b-form-select-option>
                    <b-form-select-option value="AM">AM</b-form-select-option>
                    <b-form-select-option value="AP">AP</b-form-select-option>
                    <b-form-select-option value="BA">BA</b-form-select-option>
                    <b-form-select-option value="CE">CE</b-form-select-option>
                    <b-form-select-option value="DF">DF</b-form-select-option>
                    <b-form-select-option value="ES">ES</b-form-select-option>
                    <b-form-select-option value="MA">MA</b-form-select-option>
                    <b-form-select-option value="MG">MG</b-form-select-option>
                    <b-form-select-option value="MS">MS</b-form-select-option>
                    <b-form-select-option value="MT">MT</b-form-select-option>
                    <b-form-select-option value="PA">PA</b-form-select-option>
                    <b-form-select-option value="PB">PB</b-form-select-option>
                    <b-form-select-option value="PE">PE</b-form-select-option>
                    <b-form-select-option value="PI">PI</b-form-select-option>
                    <b-form-select-option value="PR">PR</b-form-select-option>
                    <b-form-select-option value="RJ">RJ</b-form-select-option>
                    <b-form-select-option value="RO">RO</b-form-select-option>
                    <b-form-select-option value="RN">RN</b-form-select-option>
                    <b-form-select-option value="RS">RS</b-form-select-option>
                    <b-form-select-option value="RR">RR</b-form-select-option>
                    <b-form-select-option value="SC">SC</b-form-select-option>
                    <b-form-select-option value="SE">SE</b-form-select-option>
                    <b-form-select-option value="SP">SP</b-form-select-option>
                    <b-form-select-option value="TO">TO</b-form-select-option>
                    <!-- 26 estados adicionados -->
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Cidade:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input value="Selecione a UF" :readonly="true" v-if="uf == null" />
                  <vue-bootstrap-typeahead
                    v-if="uf != null"
                    id="cidade"
                    name="cidade"
                    clas="no-border"
                    placeholder="Cidade"
                    v-model="cidadeQuery"
                    :data="cidades"
                    :serializer="s => s.descricao"
                    @hit="cidade = $event"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Mensagem:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-textarea
                    class="form-control"
                    name="name"
                    rows="3"
                    cols="100"
                    placeholder="Conteúdo da Mensagem"
                    v-model="faleConosco.mensagem"
                    maxlength="255"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col :cols="2"></b-col>
            <b-col cols="8">
              <div class="send-button">
                <b-button :disabled="veeErrors.any()" class="btn-round" type="submit" variant="primary" block size="lg"
                  >Enviar</b-button
                >
              </div>
            </b-col>
          </b-row>

          <b-modal id="confirm-modal" hide-footer>
            <template slot="modal-title"
              >Solicitação enviada</template
            >
            <div class="d-block text-center">Sua solicitação foi enviada com sucesso.</div>
            <b-button class="mt-2" block @click="hideModal">Fechar</b-button>
          </b-modal>

          <b-modal id="error-modal" hide-footer>
            <template slot="modal-title"
              >Erro incluindo Solicitação</template
            >
            <div class="d-block text-center">Não foi possível registrar sua solicitação.</div>
            <b-button class="mt-2" block @click="hideModalError">Fechar</b-button>
          </b-modal>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Badge, Button, FormGroupInput } from '@/components';
import { mapState } from 'vuex';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'faleConosco',
  bodyClass: 'novaDemanda-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Badge.name]: Badge
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      faleConosco: {},
      uf: null,
      cidadeQuery: null,
      cidade: {},
      cidades: []
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  mounted() {
    if (window.config) {
      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }
    }

    numeral.locale('pt-br');
  },
  methods: {
    handleApiCidades() {
      this.cidades = [];
      const token = this.$store.state.account.user;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };
      Vue.axios
        //.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.uf}/municipios`, {})
        .get(`/cidades`, requestOpts)
        .then(response => {
          const rowData = response.data;
          for (const cid of rowData) {
            if (cid.uf === this.uf) {
              this.cidades.push(cid);
            }
          }
        });
    },

    hideModal() {
      this.$bvModal.hide('confirm-modal');
      this.$router.push('/');
    },
    hideModalError() {
      this.$bvModal.hide('error-modal');
    },
    onSubmit() {
      // Form submit logic
      const token = this.$store.state.account.user;
      const empresaAtual = this.$store.state.empresa.empresaAtual;

      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      this.faleConosco.idEmpresa = empresaAtual.id;

      if (this.cidade.id !== undefined) {
        this.faleConosco.idCidade = this.cidade.id;
      }

      Vue.axios
        .post(`/demandas/faleConosco`, this.faleConosco, requestOpts)
        .then(response => {
          const faleConosco = response.data;

          if (faleConosco.id == null || faleConosco.id == undefined) {
            this.$bvModal.show('error-modal');
          } else {
            this.$bvModal.show('confirm-modal');

            this.$notify({
              group: 'msgs',
              title: 'Confirmação',
              text: 'Solicitação incluída com sucesso.'
            });
          }
        })
        .catch(error => {
          const errorMsg = error.response ? error.response.data.message : error;
          this.$notify({
            group: 'msgsCenter',
            title: 'Erro',
            text: errorMsg,
            type: 'error'
          });
        });
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getImagemBackgound() {
      this.backgroundTopoEmpresa = "background-image: url('img/" + this.empresaAtual.sigla + "/solicitacao.jpg')";
      // console.log(this.backgroundTopoEmpresa)
    }
  }
};
</script>
<style>
table {
  font-size: 0.8571em;
}
</style>
