export const logomarca = {
  namespaced: true,
  state: {
    logomarcas: [],
    logomarcaAtual: null
  },
  actions: {
    fill({ commit }, logomarcas) {
      commit('fillLogomarcas', logomarcas);
    },
    getOne({ commit }, logomarca) {
      commit('getLogomarca', logomarca);
    },
    select({ commit }, logomarca) {
      commit('setLogomarca', logomarca);
    },
    clear({ commit }) {
      commit('clear');
    }
  },
  mutations: {
    clear(state) {
      state.logomarcas = null;
      state.logomarcaAtual = null;
    },
    fillLogomarcas(state, logomarcas) {
      state.logomarcas = logomarcas;
      state.logomarcaAtual = logomarcas[0];
    },
    getLogomarca(state, logomarca) {
      const idx = state.logomarcas.findIndex(item => item.empresa === logomarca.empresa);

      if (idx !== -1) {
        state.logomarca = state.logomarcas[idx];
      } else {
        state.logomarca = null;
      }
    },
    setLogomarca(state, logomarca) {
      state.logomarcaAtual = logomarca;
    }
  }
};
