<template>
  <div style="height: 89vh; width: 100vw">
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">Perguntas Frequentes</h2>
        </div>
      </div>
    </div>

    <!-- Verifique a baixo se algumas das suas duvidas pode ser solucionadas 
    Verifique essa área para solucionar duvidas a respeito do portal do Faixa de Domínio-->

    <div class="container-fluid">
      <div class="section section-contact-us text-center">
        <div class="container">
          <b-card class="mt-4" style="height: 56vh">
            <div class="p-3">
              <h5>Verifique essa área para solucionar duvidas a respeito do portal do Faixa de Domínio</h5>
            </div>
            <form @submit.prevent="onSubmit" class="col-md-8 ml-auto mr-auto text-center">
              <div style="height: 225px; overflow: auto;">
                <div class="accordion" role="tablist">
                  <b-card v-for="(dado, index) in dados" :key="dado.id" no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle="'accordion-' + index" variant="secondary">
                        <div v-html="dado.perguntaHtml"></div>
                      </b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                        <b-card-text>
                          <div v-html="dado.respostaHtml"></div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </form>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'faqs',
  bodyClass: 'novaDemanda-page',

  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      dados: [
        // {
        //   pergunta: '1. Como devo criar solicitações ?',
        //   resposta: "Você deve criar solicitações a partir da opção 'Nova solicitação'. "
        // },
        // {
        //   pergunta: '2. Qual é o tamanho máximo dos arquivos a serem enviados ?',
        //   resposta: 'Para cada arquivo a ser enviado vocë pode enviar um total de 10mb por arquivo.'
        // },
        // {
        //   pergunta: '3. Qual é o prazo estimado para atendimento da solicitação ?',
        //   resposta: 'O prazo médio estimado irá depender do tipo de solicitação, oscilando entre 7 a 20 dias. '
        // },
        // {
        //   pergunta: '4. Como funciona o processo da solicitação ?',
        //   resposta:
        //     '1. A solicitação é gerada; 2. A concessionária realiza a análise; 3. A concessionária aprova os dados e documentos; '
        // }
      ]
    };
  },
  mounted() {
    this.findPerguntasFrequentes();
  },
  methods: {
    findPerguntasFrequentes() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      Vue.axios
        .get(`/demandas/${sigla}/perguntasFrequentes`, options)
        .then(response => {
          this.dados = response.data;
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    }
  }
};
</script>
