<template>
  <div id="app">
    <router-view name="header" />
    <notifications group="msgs" />
    <notifications group="msgsCenter" position="top center" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>

<script>

export default {
  name: 'app',
};
</script>

<style scoped>
.vue-notification {
  font-size: 1em;
}
</style>