<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image" :style="backgroundTopo"></div>
    <div class="content">
      <form @submit.prevent="handleSubmit">
        <div class="container">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h2 class="h2-seo">Confirmação</h2>
              </div>

              <p class="lead">{{ msgTxt }}</p>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <b-btn class="btn-primary btn-round btn-block" type="submit">
                    <font-awesome-icon icon="lock" class="mr-1" />Acessar
                  </b-btn>
                </div>
              </template>
            </card>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="js">
import { Card } from '@/components';
import { userService } from '@/_services';

export default {
  name: 'confirm-page',
  bodyClass: 'register-page',
  components: {
    Card,
  },
  data() {
    return {
      msgTxt : 'Aguarde, verificando as informações de registro ...',
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
    }
  },
  methods:{
      handleSubmit() {
          this.$router.push('/')
      }
  },
  mounted() {
    const userId = this.$route.params.id;

    if (window.config && window.config.SIGLA_ASSETS) {
      const siglaAssets = window.config.SIGLA_ASSETS;
      this.backgroundLogin = `background-image: url('/img/${siglaAssets}/trevo.jpg')`;
    }

    userService.registerConfirm({nome: userId}, userId).then(
      () => {
        this.msgTxt = 'Registro do usuário foi confirmado com sucesso.';
        this.$notify({
          group: 'msgs',
          title: 'Registro',
          text: 'Registro confirmado com sucesso.',
          type: 'info'
        });

        this.$router.push('/')
      },
      error => {
        this.msgTxt = error;
        this.$notify({
            group: 'msgs',
            title: 'Erro',
            text: error,
            type: 'error'
          });
        }
    );
  }
}
</script>
