<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <form @submit.prevent="handleSubmit">
        <div class="container">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h2 class="h2-seo">Alterar Senha</h2>
              </div>

              <p class="lead">
                Sua solicitação de alteração de senha foi realizada com sucesso.
                Verifique a caixa de entrada do email para confirmação.
              </p>
            </card>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang='js'>
import { Card } from '@/components';

export default {
  name: 'confirm-page',
  bodyClass: 'register-page',
  components: {
    Card,
  },
  methods:{
      handleSubmit() {
          this.$router.push('/')
      }
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }
  },
}
</script>
