<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }" :data-background-color="backgroundColor">
    <div class="container">
      <!-- <div class="copyright">
        &copy; {{ year }}, Designed by
        <a href="https:///www.invisionapp.com" target="_blank" rel="noopener"
          >Invision</a
        >. Coded by
        <a href="https://www.oti.inf.br/" target="_blank" rel="noopener"
          >OTI</a
        >.
      </div> -->
      <h6 class="category category-absolute direita">
        Solução
        <a href="https://www.oti.inf.br" target="_blank"> <img src="img/oti-slim.png" class="otisw-logo" /> </a>
      </h6>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
