<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h1 class="h1-seo">FAIXA DE DOMÍNIO</h1>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="handleSubmit" class="col-md-9 ml-auto mr-auto text-center">
          <h2 class="title">Registre - se</h2>
          <p class="description">Crie um perfil para poder solicitar novas demandas.</p>

          <b-alert
            :show="alert != null && alert.message != null"
            :variant="alert.type === 'alert-danger' ? 'danger' : 'success'"
          >
            <span class="font-weight-bold">{{ alert.type === 'alert-danger' ? 'Ocorreu um Erro.' : 'Sucesso' }}</span>
            <p>{{ alert.message }}</p>
          </b-alert>

          <card>
            <b-form-row>
              <b-col cols="12">
                <b-form-group label="CPF ou CNPJ:" label-cols-lg="3" label-size="sm" class="text-left">
                  <b-form-input
                    :state="validateState('cpf')"
                    id="cpf"
                    name="cpf"
                    v-model="login"
                    inputId="login"
                    placeholder="CPF ou CNPJ:"
                    v-validate="'required'"
                    aria-describedby="cpf-feedback"
                    maxlength="20"
                  ></b-form-input>
                  <b-form-invalid-feedback id="cpf-feedback" :tooltip="true"
                    >É necessário informar o campo 'CPF/CNPJ'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-row>
              <b-col cols="12">
                <b-form-group label="Nome/Razão Social:" label-cols-lg="3" label-size="sm" class="text-left">
                  <b-form-input
                    :state="validateState('nome')"
                    id="nome"
                    name="nome"
                    v-model="nome"
                    placeholder="Nome/Razão Social"
                    v-validate="'required'"
                    aria-describedby="titulo-feedback"
                    maxlength="50"
                  ></b-form-input>
                  <b-form-invalid-feedback id="titulo-feedback" :tooltip="true"
                    >É necessário informar o campo 'Nome/Razão Social'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="E-mail:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    :state="validateState('email')"
                    id="email"
                    name="email"
                    v-model="email"
                    placeholder="E-mail"
                    v-validate="'required'"
                    aria-describedby="email-feedback"
                    maxlength="100"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-feedback" :tooltip="true"
                    >É necessário informar o campo 'E-mail'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Senha:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    id="senha"
                    name="senha"
                    v-model="password"
                    placeholder="Senha"
                    type="password"
                    maxlength="32"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Endereço:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input
                    id="endereco"
                    name="endereco"
                    v-model="endereco"
                    placeholder="Endereço"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label="UF:" label-cols-lg="3" label-size="sm" label-text-align="left" class="text-left">
                  <b-form-select class="form-control" v-model="uf" @change="handleApiCidades">
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AM">AM</option>
                    <option value="AP">AP</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="MA">MA</option>
                    <option value="MG">MG</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="PR">PR</option>
                    <option value="RJ">RJ</option>
                    <option value="RO">RO</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SE">SE</option>
                    <option value="SP">SP</option>
                    <option value="TO">TO</option>
                    <!-- 26 estados adicionados -->
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Cidade:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-input value="Selecione a UF" :readonly="true" v-if="uf == null" />
                  <vue-bootstrap-typeahead
                    v-if="uf != null"
                    id="cidade"
                    name="cidade"
                    clas="no-border"
                    placeholder="Cidade"
                    v-model="cidadeQuery"
                    :data="cidades"
                    @hit="cidade = $event"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="selecaoEmpresa">
              <b-col>
                <b-form-group
                  label="Concessionária:"
                  label-cols-lg="3"
                  label-size="sm"
                  label-text-align="left"
                  class="text-left"
                >
                  <b-form-select
                    :state="validateState('empresa')"
                    name="empresa"
                    class="form-control no-border"
                    v-model="empresa"
                    :options="empresas"
                    placeholder="Concessionária"
                    v-validate="'required'"
                    aria-describedby="empresa-feedback"
                  ></b-form-select>

                  <b-form-invalid-feedback id="empresa-feedback" :tooltip="true"
                    >É necessário informar o campo 'Concessionária'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </card>

          <b-row>
            <b-col cols="6">
              <b-btn class="btn-primary btn-round" size="lg" type="submit">Confirmar</b-btn>
            </b-col>
            <b-col cols="6">
              <b-btn class="btn-secondary btn-round" size="lg" @click="handleCancelar">Cancelar</b-btn>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import { Card, Button, FormGroupInput } from '@/components';
import {  mapActions } from 'vuex'
import { empresaService } from '@/_services';

export default {
  name: 'conta-page',
  bodyClass: 'register-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      login: '',
      nome: '',
      email: '',
      password: '',
      endereco: '',
      cidade: '',
      cidades:[],
      cidadeQuery: null,
      uf: null,
      empresa: '',
      empresas: [],
      idEmpresa: undefined,
      ufs: ['AC', 'AL', 'AM',
        'AP', 'BA', 'CE',
        'DF', 'ES', 'GO',
        'MA', 'MG', 'MS',
        'MT', 'PA', 'PB',
        'PE', 'PI', 'PR',
        'RJ', 'RO', 'RN',
        'RS', 'RR', 'SC',
        'SE', 'SP', 'TO'],
      selecaoEmpresa: false,
      siglaEmpresa: undefined,
      submitted: false,
      tipoDemanda: null,
      tiposAcesso: [],
      optionsEmpresas: []
    }
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    }
  },
  methods: {
    ...mapActions('account', ['register']),
    handleApiCidades() {
      this.cidades = [];
      // REST GET para dados Back-End por empresa
      Vue.axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.uf}/municipios`, {}).then(response => {
        const rowData = response.data;
        for(const cid of rowData) {
          if (this.cidades.indexOf(cid.nome) == -1) {
            this.cidades.push(cid.nome);
          }
        }
      });
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        console.log('this.siglaEmpresa=>', this.siglaEmpresa)
        for (let i = 0; i < this.optionsEmpresas.length; i++) {
          const empresa = this.optionsEmpresas[i];
          if (empresa.sigla === this.siglaEmpresa) {
            this.idEmpresa = empresa.id;
          }
        }

        this.submitted = true;
        const { login, nome, email, password, cidade, uf, endereco } = this;
        this.register({
          user: { login, nome, email, password, endereco, cidade, uf, "tipo": "R", "subtipo": this.tipoDemanda, "idEmpresa": this.idEmpresa }}
        );
      });
    },
    handleCancelar() {
      this.$store.dispatch('alert/clear');
      this.$router.push('/')
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    findEmpresas() {
      empresaService
        .findAll()
        .then((response) => {
          this.optionsEmpresas = response.data

        })
        .catch((error) => {
          this.optionsEmpresas = []
          this.busy = false
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
  },
  mounted() {
      this.$store.dispatch('alert/clear');

      if (window.config) {
        if (window.config.SIGLA_ASSETS) {
          const siglaAssets = window.config.SIGLA_ASSETS;
          this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
        }

        if (window.config.SIGLA_EMPRESA) {
          this.siglaEmpresa = window.config.SIGLA_EMPRESA;
          this.empresa = this.siglaEmpresa;
        }
      }

      if (window.config.SELECAO_EMPRESA_REGISTRO) {
          this.selecaoEmpresa = window.config.SELECAO_EMPRESA_REGISTRO;
        }

      const options = {};
      const params = {};
      options['params'] = params;

      // Tipos de Acesso
      this.tiposAcesso = [];
      this.tiposAcesso.push({value: 'FD', text: 'Faixa de Domínio'});
      this.tipoDemanda = 'FD';

      // Carrega as empresas
      Vue.axios.get(`/empresas/all`, options)
        .then(response => {
          this.empresas = [];
          const rowData = response.data;
          if (rowData != undefined) {
            for (const row of rowData) {
              this.empresas.push({ value: row.id, text: row.nomeFantasia });
            }
          }
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
        this.findEmpresas()
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch('alert/clear');
    }
  }
};
</script>
<style>
.titulo-h2 {
  color: white;
}
fundo-branco {
  background-color: #fff;
}
</style>
