<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">FAIXA DE DOMÍNIO</h2>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="handleSubmit" class="col-md-9 ml-auto mr-auto text-center">
          <h2 class="title">Perfil</h2>
          <p class="description">Atualize seus dados de Perfil</p>

          <!-- Página de perfil do usuário -->
          <div class="col-lg-12">
            <b-card>
              <b-form-group
                label-cols-lg="3"
                label="Dados gerais"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <!-- Inicio da edição do nome/razão social do usuário -->
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Nome/Razão Social:" label-cols-lg="3" label-size="sm" class="text-left">
                      <b-form-input
                        id="nome"
                        name="nome"
                        placeholder="Nome/Razão Social"
                        v-model="userInfo.nomeUsuario"
                        aria-describedby="nome-feedback"
                        maxlength="50"
                      ></b-form-input>

                      <b-form-invalid-feedback id="nome-feedback"
                        >É necessário informar o campo 'Nome/Razão Social'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Fim da edição do nome/razão social do usuário -->

                <!-- Inicio da edição do CPF/CNPJ do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group label="CPF ou CNPJ:" label-cols-lg="3" label-size="sm" class="text-left">
                      <b-form-input
                        id="cpf"
                        name="cpf"
                        placeholder="CPF ou CNPJ"
                        v-model="userInfo.cpfCnpj"
                        aria-describedby="cpf-feedback"
                        maxlength="20"
                      ></b-form-input>

                      <b-form-invalid-feedback id="cpf-feedback"
                        >É necessário informar o campo 'CPF ou CNPJ"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Inicio da edição do e-mail do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="E-mail:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-input
                        id="email"
                        name="email"
                        placeholder="E-mail"
                        v-model="userInfo.email"
                        aria-describedby="email-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="email-feedback"
                        >É necessário informar o campo 'E-mailJ"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do e-mail do usuário -->

                <!-- Inicio da edição do UF do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="UF:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <!-- <vue-bootstrap-typeahead placeholder="UF" v-model="userInfo.uf" :data="ufs" /> -->
                      <select class="form-control" v-model="userInfo.uf">
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AM">AM</option>
                        <option value="AP">AP</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="MA">MA</option>
                        <option value="MG">MG</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="PR">PR</option>
                        <option value="RJ">RJ</option>
                        <option value="RO">RO</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SE">SE</option>
                        <option value="SP">SP</option>
                        <option value="TO">TO</option>
                        <!-- 26 estados adicionados -->
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do UF do usuário -->

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Endereço:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-input
                        id="endereco"
                        name="endereco"
                        placeholder="Endereço"
                        v-model="userInfo.endereco"
                        aria-describedby="endereco-feedback"
                        maxlength="100"
                      ></b-form-input>

                      <b-form-invalid-feedback id="endereco-feedback"
                        >É necessário informar o campo 'Endereço"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do endereço do usuário -->
              </b-form-group>
              <b-form-group
                label-cols-lg="3"
                label="Dados adicionais"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >
                <!-- Inicio da edição do local de trabalho do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Local de Trabalho:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-input
                        id="local"
                        name="local"
                        placeholder="Local de Trabalho"
                        v-model="userInfo.localTrabalho"
                        aria-describedby="local-feedback"
                        maxlength="50"
                      ></b-form-input>

                      <b-form-invalid-feedback id="local-feedback"
                        >É necessário informar o campo 'Local de Trabalho'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do local de trabalho do usuário -->

                <!-- Inicio da edição do cargo do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Cargo:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-input
                        id="cargo"
                        name="cargo"
                        placeholder="Cargo"
                        v-model="userInfo.cargo"
                        aria-describedby="cargo-feedback"
                        maxlength="50"
                      ></b-form-input>

                      <b-form-invalid-feedback id="cargo-feedback"
                        >É necessário informar o campo 'Cargo"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do cargo do usuário -->

                <!-- Inicio da edição do formação adacêmica do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Formação Acadêmica:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <select
                        class="form-control"
                        id="formacao"
                        name="formacao"
                        v-model="userInfo.formacao"
                        aria-describedby="formacao-feedback"
                      >
                        <option :value="null">Selecione a formação</option>
                        <option value="EFI">Ensino fundamental incompleto</option>
                        <option value="EFC">Ensino fundamental completo</option>
                        <option value="EMC">Ensino médio completo</option>
                        <option value="EMI">Ensino médio incompleto</option>
                        <option value="SUP">Ensino superior completo</option>
                        <option value="SUI">Ensino superior incompleto</option>
                        <option value="POS">Pós - graduação</option>
                        <option value="MSC">Mestrado</option>
                        <option value="DOC">Doutorado</option>
                        <option value="PDC">Pós - doutorado</option>
                      </select>

                      <b-form-invalid-feedback id="formacao-feedback"
                        >É necessário informar o campo 'Formação Acadêmica"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do formação adacêmica do usuário -->

                <!-- Inicio da edição do rodovia que utilizo do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Rodovias que utiliza:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-select
                        :options="rodovias"
                        class="form-control"
                        id="rodovia"
                        name="rodovia"
                        v-model="userInfo.rodovia"
                        aria-describedby="area-feedback"
                      ></b-form-select>
                      <b-form-invalid-feedback id="rodovias-feedback"
                        >É necessário informar o campo 'Rodovias que utiliza"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do rodovia que utilizo do usuário -->

                <!-- Inicio da edição do frequência de utilização da rodovia do usuário -->
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Utilização da rodovia:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <b-form-select
                        :options="utilizacoesRodovia"
                        class="form-control form-control-sm"
                        name="utilizacaoRodovia"
                        placeholder="Frequência de utilização da rodovia"
                        v-model="userInfo.utilizacaoRodovia"
                        aria-describedby="utilizacaoRodovia-feedback"
                      />
                      <b-form-invalid-feedback id="utilizacaoRodovia-feedback"
                        >É necessário informar o campo 'Utilização da rodovia"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do rodovia que utilizo do usuário -->

                <!-- Inicio da edição do tipo de veículo do usuário -->
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Tipo de veículo:"
                      label-cols-lg="3"
                      label-size="sm"
                      label-text-align="left"
                      class="text-left"
                    >
                      <!-- <b-form-input id="veiculo" name="veiculo" placeholder="Tipo de veículo" aria-describedby="veiculo-feedback"></b-form-input> -->
                      <b-form-select
                        :options="tiposVeiculos"
                        class="form-control form-control-sm"
                        name="tipoVeiculo"
                        v-model="userInfo.tipoVeiculo"
                        aria-describedby="veiculo-feedback"
                      />
                      <b-form-invalid-feedback id="veiculo-feedback"
                        >É necessário informar o campo 'Tipo de veículo"'.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Fim da edição do tipo de veículo do usuário -->
              </b-form-group>
            </b-card>

            <!-- Edição do botão -->
            <b-row>
              <b-col>&nbsp;</b-col>
              <b-col cols="10" class="mb-1">
                <b-button pill :disabled="veeErrors.any()" type="submit" variant="primary" round block size="lg"
                  >Atualizar</b-button
                >
              </b-col>
              <b-col>&nbsp;</b-col>
            </b-row>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button, FormGroupInput } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'perfil',
  bodyClass: 'perfil-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      rodovias: [],
      userInfo: {
        uf: null,
        rodovia: {},
        utilizacaoRodovia: null,
        tipoVeiculo: null
      },
      utilizacoesRodovia: [
        { value: null, text: 'Selecione a utilização da rodovia' },
        { value: 0, text: 'Não informado' },
        { value: 1, text: '1x ao dia' },
        { value: 2, text: 'Ao menos 3x por semana' },
        { value: 3, text: '1x por semana' },
        { value: 4, text: '1x ao mês' }
      ],
      tiposVeiculos: [
        { value: null, text: 'Selecione o tipo do veículo' },
        { value: 1, text: 'Automóvel, Caminhonete e Furgão' },
        { value: 2, text: 'Caminhão leve, ônibus, Caminhão-trator e furgão' },
        { value: 3, text: 'Automóvel com semirreboque e caminhão-trator com semirreboque' },
        { value: 4, text: 'Caminhão, Caminhão-trator e caminhão-trator com semirreboque' },
        { value: 5, text: 'Automóvel com reboque e caminhonete com reboque' },
        { value: 6, text: 'Caminhão com reboque e caminhão-trator com semirreboque' },
        { value: 7, text: 'Motocicleta, motoneta, bicicleta a motor e triciclo' }
      ]
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
      const siglaAssets = window.config.SIGLA_ASSETS;
      this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
    }

    this.fillData();
  },
  methods: {
    fillData() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      this.rodovias = [{ value: null, text: 'Selecione a rodovia' }];

      Vue.axios.get(`/usuarios/info`, options).then(response => {
        this.userInfo = response.data;

        // Rodovias
        if (this.userInfo.rodovias) {
          for (var rdv of this.userInfo.rodovias) {
            this.rodovias.push({ value: rdv.id, text: rdv.descricao });
          }

          this.userInfo.rodovias = undefined;
        }

        if (!this.userInfo.rodovia) this.userInfo.rodovia = {};

        if (this.userInfo.rodovia) {
          // vem em JSON (id e descricao, precisa normalizar)
          this.userInfo.rodovia = this.userInfo.rodovia.id;
        }
      });
    },
    handleSubmit() {
      const token = this.$store.state.account.user;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      let payload = {};
      payload.endereco = this.userInfo.endereco;
      payload.cpfCnpj = this.userInfo.cpfCnpj;
      payload.uf = this.userInfo.uf;
      payload.localTrabalho = this.userInfo.localTrabalho;
      payload.formacao = this.userInfo.formacao;
      if (this.userInfo.rodovia) payload.idRodovia = this.userInfo.rodovia;
      payload.utilizacaoRodovia = this.userInfo.utilizacaoRodovia;
      payload.tipoVeiculo = this.userInfo.tipoVeiculo;

      payload.usuario = {};
      payload.usuario.login = this.userInfo.login;
      payload.usuario.nome = this.userInfo.nomeUsuario;
      payload.usuario.email = this.userInfo.email;
      payload.usuario.cargo = this.userInfo.cargo;

      Vue.axios
        .post(`/usuarios/info`, payload, requestOpts)
        .then(() => {
          this.$notify({
            group: 'msgs',
            title: 'Resultado',
            text: 'Perfil alterado com sucesso!'
          });
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    }
  }
};
</script>
<style></style>
